import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PropertyPage = () => {
  const hosturl = 'http://localhost:4000';
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filters, setFilters] = useState({
    location: '',
    type: '',
    size: '',
    rent: ''
  });
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    loadProperties();
  }, []);

  const loadProperties = async () => {
    try {
      const response = await axios.get(`${hosturl}/api/properties`);
      setProperties(response.data);
      setFilteredProperties(response.data);
    } catch (error) {
      console.error('Error fetching properties:', error);
    }
  };

  const applyFilters = () => {
    const { location, type, size, rent } = filters;
    const maxRent = parseInt(rent, 10) || Infinity;

    const result = properties.filter(property => {
      return (
        (location === '' || property.location.toLowerCase().includes(location.toLowerCase())) &&
        (type === '' || property.type.toLowerCase() === type.toLowerCase()) &&
        (size === '' || property.size.toLowerCase() === size.toLowerCase()) &&
        property.rent <= maxRent
      );
    });

    setFilteredProperties(result);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const updateSizeOptions = (type) => {
    let sizes = [];
    if (type === 'commercial') {
      sizes = ['Shutter', 'Office', 'Shop'];
    } else if (type === 'residential') {
      sizes = ['1RK', '1BHK', '2BHK', '3BHK', 'DuplexHouse'];
    } else {
      sizes = [];
    }

    return sizes.map(size => (
      <option key={size} value={size.toLowerCase()}>{size}</option>
    ));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); 
  };

  const fetchproperties = async (userId) => {
    try {
      const response = await axios.get(`${hosturl}/api/users/${userId}`);
      setUserDetails(response.data);
    } catch (error) {
      console.error('Error fetching properties details:', error);
    }
  };

  const handleOwnerButtonClick = async (userId) => {
    if (expandedUserId === userId) {
      // Collapse if already expanded
      setExpandedUserId(null);
      setUserDetails({});
    } else {
      // Fetch new user details and expand
      await fetchproperties(userId);
      setExpandedUserId(userId);
    }
  };

  return (
    <div style={styles.page}>
      <h5 style={styles.title}>Filter Properties</h5>
      <div style={styles.formContainer}>
        <form onSubmit={(e) => { e.preventDefault(); applyFilters(); }} style={styles.form}>
          <div style={styles.formGroup}>
            <label htmlFor="location" style={styles.label}>Location:</label>
            <input
              type="text"
              id="location"
              name="location"
              value={filters.location}
              onChange={handleFilterChange}
              placeholder="Enter location"
              style={styles.input}
            />
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="property-type" style={styles.label}>Property Type:</label>
            <select
              id="property-type"
              name="type"
              value={filters.type}
              onChange={(e) => {
                handleFilterChange(e);
                updateSizeOptions(e.target.value);
              }}
              style={styles.input}
            >
              <option value="">Select Property Type</option>
              <option value="commercial">Commercial</option>
              <option value="residential">Residential</option>
            </select>
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="size" style={styles.label}>Size:</label>
            <select
              id="size"
              name="size"
              value={filters.size}
              onChange={handleFilterChange}
              style={styles.input}
            >
              <option value="">Select Size</option>
              {updateSizeOptions(filters.type)}
            </select>
          </div>

          <div style={styles.formGroup}>
            <label htmlFor="rent" style={styles.label}>Rent (in ₹):</label>
            <input
              type="number"
              id="rent"
              name="rent"
              value={filters.rent}
              onChange={handleFilterChange}
              placeholder="Enter max rent"
              style={styles.input}
            />
          </div>

          <button type="submit" style={styles.button}>Apply Filters</button>
        </form>
      </div>

      <div style={styles.tableContainer}>
        <h4 style={styles.subtitle}>Properties</h4>
        <table style={styles.table}>
          <thead>
            <tr style={styles.headerRow}>
              <th style={styles.headerCell}>S.No</th>
              <th style={styles.headerCell}>Date</th>
              <th style={styles.headerCell}>Building</th>
              <th style={styles.headerCell}>Rent (₹)</th>
              <th style={styles.headerCell}>Location</th>
              <th style={styles.headerCell}>Size</th>
              <th style={styles.headerCell}>Area (Sqft)</th>
              <th style={styles.headerCell}>Owner</th>
            </tr>
          </thead>
          <tbody>
            {filteredProperties.length > 0 ? filteredProperties.map((property, index) => (
              <React.Fragment key={property._id}>
                <tr style={styles.tableRow}>
                  <td style={styles.cell}>{index + 1}</td>
                  <td style={styles.cell}>{formatDate(property.uploadDate)}</td>
                  <td style={styles.cell}>{property.building}</td>
                  <td style={styles.cell}>₹ {property.rent.toLocaleString()}</td>
                  <td style={styles.cell}>{property.location}</td>
                  <td style={styles.cell}>{property.size}</td>
                  <td style={styles.cell}>{property.area}</td>
                  <td style={styles.cell}>
                    <button 
                      style={styles.ownerButton}
                      onClick={() => handleOwnerButtonClick(property.userId)}
                    >
                      {expandedUserId === property.userId ? 'Hide Details' : 'Contact Owner'}
                    </button>
                  </td>
                </tr>
                {expandedUserId === property.userId && (
                  <tr style={styles.detailsRow}>
                    <td colSpan="8" style={styles.detailsCell}>
                      <div style={styles.userDetailsDropdown}>
                        <h6><strong>Owner Details</strong></h6>
                        <p><strong>Name:</strong> {userDetails.username}</p>
                        <p><strong>Email:</strong> {userDetails.email}</p>
                        <p><strong>Phone:</strong> {userDetails.phoneNumber}</p>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )) : <tr><td colSpan="8" style={styles.cell}>No properties match your criteria.</td></tr>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  page: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    margin: '20px 0',
    color: '#007bff',
  },
  subtitle: {
    margin: '20px 0',
    color: '#007bff',
  },
  formContainer: {
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #ddd',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  button: {
    padding: '10px 15px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
  },
  tableContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '0 20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  headerRow: {
    backgroundColor: '#007bff',
    color: '#fff',
  },
  headerCell: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  cell: {
    border: '1px solid #ddd',
    padding: '12px',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  tableRow: {
    transition: 'background-color 0.3s ease',
  },
  ownerButton: {
    padding: '6px 12px',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s ease',
  },
  detailsRow: {
    backgroundColor: '#f9f9f9',
    borderTop: '1px solid #ddd',
  },
  detailsCell: {
    padding: '10px',
    position: 'relative',
  },
  userDetailsDropdown: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    padding: '10px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    fontSize:'15px'
  },
};

export default PropertyPage;
