import React, { useState } from 'react';

const ImageUpload = () => {
  const [images, setImages] = useState(Array(6).fill(null));
  const [imageSelected, setImageSelected] = useState(false);
  const hosturl = 'http://localhost:4000';

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      setImages((prevImages) => {
        const newImages = [...prevImages];
        newImages[index] = file;
        setImageSelected(newImages.some(img => img !== null));
        return newImages;
      });
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    images.forEach((image, index) => {
      if (image) formData.append('image', image); // Use 'image' as the field name
    });

    try {
      const response = await fetch(`${hosturl}/api/photogallery`, {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        alert('Images submitted successfully!');
        setImages(Array(6).fill(null));
        setImageSelected(false);
      } else {
        alert('Error submitting images');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting images');
    }
  };

  return (
    <div style={styles.container}>
      <h5 style={styles.title}>Photo Gallery</h5>
      <div style={styles.formContainer}>
        {images.map((image, index) => (
          <div key={index} style={styles.inputWrapper}>
            <label style={styles.label}>
              {`Image ${index + 1}`}
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e, index)}
                style={styles.input}
              />
            </label>
            <div style={styles.imagePreview}>
              {image && (
                <img
                  src={URL.createObjectURL(image)}
                  alt={`uploaded-${index}`}
                  style={styles.image}
                />
              )}
            </div>
          </div>
        ))}
        <button
          style={{
            ...styles.submitButton,
            ...(imageSelected ? {} : styles.submitButtonDisabled),
          }}
          onClick={handleSubmit}
          disabled={!imageSelected}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    margin: '10px',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '30px',
    color: '#444',
    textAlign: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '15px',
    width: '700px',
    background: '#f9f9f9',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontWeight: 'bold',
  },
  input: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  imagePreview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    overflow: 'hidden',
    borderRadius: '5px',
    border: '1px solid #ddd',
    background: '#fff',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  submitButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, transform 0.3s',
    width: '150px',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
  },
  submitButtonDisabled: {
    backgroundColor: '#ccc',
    cursor: 'not-allowed',
  },
};

export default ImageUpload;
