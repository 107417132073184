import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationForm = () => {
  const [selectedCity, setSelectedCity] = useState('');
  const [subCities, setSubCities] = useState(['']);
  const [locations, setLocations] = useState([]);
  const [menuVisible, setMenuVisible] = useState(null);
  const [editingLocation, setEditingLocation] = useState(null);
  const [commissionPercentage, setCommissionPercentage] = useState('');
  const hosturl = 'http://localhost:4000';

  useEffect(() => {
    fetchLocations();
  }, []);
  
  const fetchLocations = async () => {
    try {
      const response = await axios.get(`${hosturl}/locations`);
      console.log('Fetched Locations:', response.data); // Log data to verify
      setLocations(response.data);
    } catch (err) {
      console.error('Error fetching locations:', err);
    }
  };
  
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const handleInputChange = (index, event) => {
    const newSubCities = [...subCities];
    newSubCities[index] = event.target.value;
    setSubCities(newSubCities);
  };
  
  const addSubCityInput = () => {
    setSubCities([...subCities, '']);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingLocation) {
        if (!editingLocation._id) { 
          throw new Error('Editing location ID is missing');
        }
        await axios.put(`${hosturl}/locations/${editingLocation._id}`, {
          city: selectedCity,
          subCities,
        });
        alert('Location Updated Successfully');
        setEditingLocation(null);
      } else {
        await axios.post(`${hosturl}/locations`, {
          city: selectedCity,
          subCities,
        });
        alert('Location Added Successfully');
      }
  
      setSelectedCity('');
      setSubCities(['']);
      setCommissionPercentage(''); 
      fetchLocations();
    } catch (err) {
      console.error('Error saving location:', err.response ? err.response.data : err.message);
    }
  };
  
  const handleEdit = (location) => {
    if (location && location._id) { 
      setEditingLocation(location);
      setSelectedCity(location.city);
      setSubCities(location.subCities);
    } else {
      console.error('Location or Location ID is missing');
    }
  };  
  
  const handleDelete = async (locationId) => {
    console.log('Deleting Location ID:', locationId); // Log to verify ID
    const confirmDelete = window.confirm('Are you sure you want to delete this location?');
    if (!confirmDelete) return;
  
    try {
      const response = await axios.delete(`${hosturl}/locations/${locationId}`);
      if (response.status === 200) {
        alert('Location Deleted Successfully');
        fetchLocations();
      }
    } catch (err) {
      console.error('Error deleting location:', err.response ? err.response.data : err.message);
    }
  };
  
  const toggleMenu = (index) => {
    setMenuVisible(menuVisible === index ? null : index);
  };
  console.log('Locations:', locations);

  return (
    <>
      <h5 style={styles.headingStyle}>Add/Edit Location</h5>
      <div style={styles.container}>
        <form style={styles.formStyle} onSubmit={handleSubmit}>
          <label htmlFor="city" style={styles.labelStyle}>City:</label>
          <input
            type="text"
            id="city"
            name="city"
            value={selectedCity}
            onChange={handleCityChange}
            placeholder="Enter city name"
            style={styles.inputStyle}
          />
          {selectedCity && (
            <div style={styles.subCitiesContainerStyle}>
              <div style={styles.subCitiesGridStyle}>
                {subCities.map((subCity, index) => (
                  <div key={index} style={styles.subCityWrapperStyle}>
                    <input
                      type="text"
                      value={subCity}
                      onChange={(e) => handleInputChange(index, e)}
                      placeholder={`Area ${index + 1}`}
                      style={styles.subCityInputStyle}
                    />
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addSubCityInput}
                  style={styles.addButtonStyle}
                >
                  Add More
                </button>
              </div>
              {/* <div style={styles.commissionContainerStyle}>
                <label htmlFor="commission" style={styles.commissionLabelStyle}>Property Commission Percentage:</label>
                <input
                  type="number"
                  id="commission"
                  name="commission"
                  value={commissionPercentage}
                  placeholder="Enter percentage"
                  style={styles.inputStyle}
                />
              </div> */}
              <button
                type="submit"
                style={styles.submitButtonStyle}
              >
                {editingLocation ? 'Update Location' : 'Add Location'}
              </button>
            </div>
          )}
        </form>
      </div>

      <div style={styles.locationsContainerStyle}>
        <h5 style={styles.headingStyle}>Locations</h5>
        {locations.length > 0 ? (
          <ul style={styles.locationsListStyle}>
            {locations.map((location, index) => (
              <li key={location._id} style={styles.locationItemStyle}>
                <strong>{location.city}</strong>
                <ul style={styles.subCitiesListStyle}>
                  {location.subCities.map((subCity, idx) => (
                    <li key={idx} style={styles.subCityItemStyle}>
                      {subCity}
                    </li>
                  ))}
                </ul>
                {/* <p style={styles.commissionStyle}>Commission: {location.commissionPercentage}%</p>  */}
                <button
                  style={styles.optionsButtonStyle}
                  onClick={() => toggleMenu(index)}
                >
                  &#8230; 
                </button>
                {menuVisible === index && (
                  <div style={styles.menuStyle}>
                    <button
                      style={styles.menuItemStyle}
                      onClick={() => handleEdit(location)}
                    >
                      Edit
                    </button>
                    <button
                      style={styles.menuItemStyle}
                      onClick={() => handleDelete(location._id)}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p style={styles.noLocationsStyle}>No locations available.</p>
        )}
      </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '30px',
    backgroundColor: '#f9f9f9',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif',
  },
  headingStyle: {
    color: '#333',
    marginBottom: '20px',
    marginLeft: '10px'
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  labelStyle: {
    fontSize: '1.2em',
    color: '#555',
    marginBottom: '5px',
  },
  inputStyle: {
    padding: '12px',
    fontSize: '1em',
    border: '1px solid #ddd',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'border-color 0.3s ease',
    outline: 'none',
  },
  subCitiesContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  subCitiesGridStyle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(220px, 1fr))',
    gap: '15px',
    alignItems: 'center',
  },
  subCityWrapperStyle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  subCityInputStyle: {
    padding: '10px',
    fontSize: '0.9em',
    border: '1px solid #ddd',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'border-color 0.3s ease',
    outline: 'none',
  },
  addButtonStyle: {
    padding: '10px 0px',
    fontSize: '1em',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    width: '150px',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  submitButtonStyle: {
    padding: '12px 20px',
    fontSize: '1.1em',
    border: 'none',
    borderRadius: '8px',
    backgroundColor: '#28a745',
    color: '#fff',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  },
  commissionContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  commissionLabelStyle: {
    fontSize: '1.2em',
    color: '#555',
    marginBottom: '5px',
  },
  commissionStyle: {
    fontSize: '1em',
    color: '#555',
    marginTop: '10px',
  },
  locationsContainerStyle: {
    marginTop: '30px',
  },
  locationsHeadingStyle: {
    fontSize: '1.2em',
    marginBottom: '15px',
    color: '#333',
  },
  locationsListStyle: {
    listStyleType: 'none',
    padding: '0',
  },
  locationItemStyle: {
    marginBottom: '10px',
    padding: '10px',
    backgroundColor: '#f1f1f1',
    borderRadius: '5px',
    position: 'relative',
  },
  subCitiesListStyle: {
    listStyleType: 'circle',
    marginLeft: '20px',
    padding: '0',
  },
  subCityItemStyle: {
    marginBottom: '5px',
  },
  noLocationsStyle: {
    fontStyle: 'italic',
    color: '#777',
  },
  optionsButtonStyle: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'transparent',
    border: 'none',
    fontSize: '1.5em',
    cursor: 'pointer',
  },
  menuStyle: {
    position: 'absolute',
    top: '30px',
    right: '10px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
  },
  menuItemStyle: {
    display: 'block',
    padding: '10px 15px',
    fontSize: '1em',
    border: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    textAlign: 'left',
    width: '100%',
    transition: 'background-color 0.2s ease',
  },
  menuItemHover: {
    backgroundColor: '#f1f1f1',
  },
};

export default LocationForm;
