import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
const hosturl = 'http://localhost:4000';

const FeedbackForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    profession: '',
    image: null,
    message: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('profession', formData.profession);
    formDataToSend.append('message', formData.message);
    if (formData.image) formDataToSend.append('image', formData.image);
  
    try {
      const response = await fetch(`${hosturl}/api/feedback`, {
        method: 'POST',
        body: formDataToSend,
      });
  
      if (!response.ok) throw new Error('Network response was not ok');
  
      const result = await response.json();
      alert('Feedback submitted successfully!');
      console.log(result);
    } catch (error) {
      console.error('There was a problem with the submission:', error);
      alert('Failed to submit feedback. Please try again.');
    }
  };
  

  return (
    <div>
      <h4>Feedback</h4>
    <div style={styles.container}>
      <form onSubmit={handleSubmit}>
        <div style={styles.formGroup}>
          <label htmlFor="name" style={styles.label}>
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="profession" style={styles.label}>
            Profession:
          </label>
          <input
            type="text"
            id="profession"
            name="profession"
            value={formData.profession}
            onChange={handleChange}
            style={styles.input}
            required
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="image" style={styles.label}>
            Upload Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="message" style={styles.label}>
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={styles.textarea}
            required
          ></textarea>
        </div>
        <button
          type="submit"
          style={{
            ...styles.button,
            ':hover': styles.buttonHover,
          }}
        >
          Submit
        </button>
      </form>
    </div>
    </div>
  );
};

export default FeedbackForm;

const styles = {
    container: {
      maxWidth: '600px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '15px',
    },
    label: {
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    input: {
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
    textarea: {
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      resize: 'vertical',
    },
    button: {
      padding: '10px 15px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#007bff',
      color: 'white',
      fontSize: '16px',
      cursor: 'pointer',
    },
    buttonHover: {
      backgroundColor: '#0056b3',
    },
  };