import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome,faUserTie,faCalendarCheck, 
    faImage,faComments,faBuilding, 
    faPowerOff,faSearch, faEnvelope,faBell,faNewspaper,
    faMapMarkerAlt ,faBars,faUser} from '@fortawesome/free-solid-svg-icons';
import Logo from './icon-deal.png';
import './HomePageComponent.css';
import LocationForm from './LocationformComponent';
import PropertyPage from './PropertiesPageComponent';
import Agentsform from './AgentsformComponent';
import Appointmentlist from './Appointmentslist';
import FeedbackForm from './FeedbackComponent';
import PhotoGallery from './PhotogalleryComponent';
import Profile from './ProfileComponent';
export default function HomePageComponent() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [activeMenu, setActiveMenu] = useState('dashboard');
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/dashboard');
        }
    }, [location.pathname, navigate]);

    const toggleSidebar = () => setIsSidebarOpen(prev => !prev);

    const handleMenuClick = (menu, route) => {
        setActiveMenu(menu);
        setActiveSubMenu(prev => prev === menu ? null : menu);
        navigate(route);
    };

    return (
        <div className="page-container">
            <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
                <div className="sidebar-logo-container">
                    <img className="sidebar-logo-icon" src={Logo} alt="AWG Circle" />
                    {isSidebarOpen && (
                        <div className="sidebar-company-info">
                            {/* <h4 className="sidebar-company-name">Book A Rent House</h4> */}
                        </div>
                    )}
                </div>

                <div className="sidebar-menu">
                    <div className="menu-item" onClick={() => handleMenuClick('profile', '/profile')}>
                        {isSidebarOpen && <span>Profile</span>}
                        <FontAwesomeIcon icon={faHome} className="sidebar-icon" />
                    </div>

                    <div className={`sidemenu-part ${activeSubMenu === 'Plans' ? 'active' : ''}`}>
                        <div className="menu-item" onClick={() => handleMenuClick('Plans', '/plans')}>
                            {isSidebarOpen && <span>Users </span>}
                            <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
                        </div>
                        <div className={`submenu ${activeSubMenu === 'Plans' ? 'submenu-open' : ''}`}>
                            <span onClick={() => navigate('/plans/current')}>Paid list</span>
                            <span onClick={() => navigate('/plans/add')}>Non Paid</span>
                        </div>
                    </div>
                    <div className="menu-item" onClick={() => handleMenuClick('location', '/location')}>
                        {isSidebarOpen && <span>Locations</span>}
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="sidebar-icon" />
                    </div>

                    <div className="menu-item" onClick={() => handleMenuClick('properties', '/properties')}>
                        {isSidebarOpen && <span>Properties</span>}
                        <FontAwesomeIcon icon={faBuilding} className="sidebar-icon" />
                    </div>

                    <div className="menu-item" onClick={() => handleMenuClick('agentform', '/agentform')}>
                        {isSidebarOpen && <span>Agents list</span>}
                        <FontAwesomeIcon icon={faUserTie} className="sidebar-icon" />
                    </div>

                    <div className="menu-item" onClick={() => handleMenuClick('Appointmentlist', '/Appointmentlist')}>
                        {isSidebarOpen && <span>Appointment List</span>}
                        <FontAwesomeIcon icon={faCalendarCheck} className="sidebar-icon" />
                    </div>

                    <div className="menu-item" onClick={() => handleMenuClick('charts', '/charts')}>
                        {isSidebarOpen && <span>Newsletter list</span>}
                        <FontAwesomeIcon icon={faNewspaper} className="sidebar-icon" />
                    </div>
                    
                    <div className="menu-item" onClick={() => handleMenuClick('photogallery', '/photogallery')}>
                        {isSidebarOpen && <span>Photo Gallery</span>}
                        <FontAwesomeIcon icon={faImage} className="sidebar-icon" />
                    </div>
                    
                    <div className="menu-item" onClick={() => handleMenuClick('feedback', '/feedback')}>
                        {isSidebarOpen && <span>Feedback</span>}
                        <FontAwesomeIcon icon={faComments} className="sidebar-icon" />
                    </div>
                    
                    <div className="menu-item" onClick={() => handleMenuClick('user-pages', '/user-pages')}>
                        {isSidebarOpen && <span>Logout</span>}
                        <FontAwesomeIcon icon={faPowerOff} className="sidebar-icon" />
                    </div>
                </div>
            </div>

            <div className={`header-container ${isSidebarOpen ? 'header-expanded' : 'header-collapsed'}`}>
                <div className="header-content">
                    <FontAwesomeIcon icon={faBars} className="menu-icon" color='#ccc' size="lg" onClick={toggleSidebar} />

                    <div className="search-container">
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input type="text" placeholder="Search projects" className="search-input" />
                    </div>

                    <div className="actions-container">
                        <FontAwesomeIcon icon={faEnvelope} className="mail-icon" />
                        <FontAwesomeIcon icon={faBell} className="notification-icon" />
                        <FontAwesomeIcon icon={faPowerOff} className="power-icon" />
                    </div>
                </div>
            </div>
        
            <div className={`main-content ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
                <Routes>
                    <Route path="/Appointmentlist" element={<Appointmentlist />} />
                    <Route path="/location" element={<LocationForm />} />
                     <Route path="/profile" element={<Profile />} />
                    {/*<Route path="/plans/add" element={<AddPlansComponent />} />
                    <Route path="/plans/delete" element={<DeletePlansComponent />} /> */}
                     <Route path="/properties" element={<PropertyPage />} /> 
                     <Route path="/agentform" element={<Agentsform />} /> 
                      <Route path="/feedback" element={<FeedbackForm />} />
                     <Route path="/photogallery" element={<PhotoGallery />} />
                     {/*<Route path="/charts" element={<ChartsComponent />} />
                     <Route path="/user-pages" element={<UserPagesComponent />} /> 
                      <Route path="/AddBank" element={<AddBankcomponent/>} />  */}
                </Routes>
            </div>
        </div>
    );
}
